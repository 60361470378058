import _ from 'underscore';
import moment from "moment";

// 航司组件
import airLineBox from "@/page/distributor/air-ticket/components/airLine";
// 机票站点选择组件
import FlightCity
    from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/domestic-ticket-airport-selector/1.2.4/index.vue";
// 国外机票站点组件
import InternationalTicketAirportSelector
    from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/international-ticket-airport-selector/1.0.1/index.vue";

// 火车票城市组件
import TrainCity from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.2/index.vue";
// 旅游城市组件
import TravelCitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/travel-city-selector/1.2.0/index.vue";

// 国内酒店城市组件
import HotelDomesticCitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/hotel-domestic-city-selector/1.0.0/index.vue";
// 国外酒店城市组件
import HotelForeignCitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/hotel-foreign-city-selector/1.0.0/index.vue";

// 获取定位城市
import get_local_city from "@/lib/common-service/get_local_city/index.js";

// 通过ip获取定位城市
import web_common_TourismMall_getArea from "@/lib/data-service/default/web_common_TourismMall_getArea";
// 国内航空公司查询接口
import get_airlines from "@/lib/data-service/flight/flight_domestic_airlines";

// 旅游
// 国内城市接口接口
import get_recommendCityList from "@/lib/data-service/default/web_common_TourismMall_recommendCityList";
// 首页热门城市的列表
import get_hotCityList from "@/lib/data-service/default/web_common_TourismMall_hotCityList";

// 首推产品列表
import get_recommend_productList from "@/lib/data-service/default/web_common_TourismMall_homeRecommendProductList";
// 分类管理产品列表
import get_productList from "@/lib/data-service/default/web_common_TourismMall_tourismProductList";

// 轮播图
import web_common_advert_showList from "@/lib/data-service/default/web_common_advert_showList";
// 代付款列表
import web_common_TourismMall_waitPaymentList from "@/lib/data-service/default/web_common_TourismMall_waitPaymentList";
// 公告
import web_common_TourismMall_noticeList from "@/lib/data-service/default/web_common_TourismMall_noticeList";

// 特供模块展示
import web_common_TourismMall_specialSupplyList
    from "@/lib/data-service/default/web_common_TourismMall_specialSupplyList";
// 用户注册信息
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";
// 通过城市名字获取城市实体对象
import hotel_common_buyerBase_getCity from "@/lib/data-service/hotel/hotel_common_buyerBase_getCity";

// pnr 导入
import buyer_dom_white_pnrImport from "@/lib/data-service/flight/buyer_dom_white_pnrImport";

export default {
    components: {
        // 机票城市选择组件
        FlightCity,
        // 航司选择组件
        airLineBox,
        // 火车票城市选择组件
        TrainCity,
        InternationalTicketAirportSelector,
        // 旅游城市组件
        TravelCitySelector,
        // 国内酒店城市组件
        HotelDomesticCitySelector,
        // 国内酒店城市组件
        HotelForeignCitySelector,
    },
    data() {
        return {
            loading: false,
            cityName: "",
            // 业务类型
            businessType: "1",
            businessList: [
                {text: "机票", value: "1"},
                {text: "火车票", value: "4"},
                {text: "旅游", value: "2"},
                {text: "酒店", value: "3"},
            ],
            pickerOptions: {
                disabledDate(time) {
                    return (
                        moment(time.getTime()).format("YYYYMMDD") <
                        moment(Date.now()).format("YYYYMMDD")
                    );
                },
            },
            trainFromPickerOptions: {
                disabledDate(time) {
                    return (
                        moment(time.getTime()).format("YYYYMMDD") <
                        moment(Date.now()).format("YYYYMMDD") ||
                        moment(time.getTime()).format("YYYYMMDD") >
                        moment(Date.now())
                            .add(2, "months")
                            .format("YYYYMMDD")
                    );
                },
            },
            tiem1PickerOptions: {
                disabledDate(time) {
                    return (
                        moment(time.getTime()).format("YYYYMMDD") <
                        moment(Date.now()).format("YYYYMMDD")
                    );
                },
            },
            tiem2PickerOptions: {
                disabledDate(time) {
                    return (
                        moment(time.getTime()).format("YYYYMMDD") <
                        moment(Date.now()).format("YYYYMMDD")
                    );
                },
            },
            // 机票表单
            flightForm: {
                airType: 1, //1单程，2往返

                starCity: "", //出发城市三字码
                starCity1: "", //出发城市名称

                endCity: "", //目的城市三字码
                endCity1: "", //目的城市名称

                time1: Date.now(), //出发时间
                time2: null, //返程时间
                time2Show: true,
                ClassType: 0, //舱位：0不限，1头等舱，2公务舱，3经济舱
                psgType: 1, //乘客类型：1成人，2儿童，3成人+儿童

                airlines: "", //航司

                adultCount: "", //成人人数
                childCount: "", //儿童人数
                babyCount: "", //婴儿人数
            },
            // 机票国际城市
            flightFromCityObj: {},
            flightToCityObj: {},

            flightTabValue: "3",
            // 国内国外
            flightTabList: [
                {label: "PNR下单", name: "3"},
                {label: "国内", name: "1"},
                {label: "国际/中国港澳台", name: "2"},
            ],
            // 往返
            airTypeList: [
                {label: 1, text: "单程"},
                {label: 2, text: "往返"},
            ],
            // 舱位
            ClassTypeList: [
                {label: "不限", value: 0},
                {label: "头等舱", value: 1},
                {label: "公务舱", value: 2},
                {label: "经济舱", value: 3},
            ],
            ClassTypeList1: [
                {label: "头等舱", value: 1},
                {label: "公务舱", value: 2},
                {label: "经济舱", value: 3},
            ],
            // 乘客类型
            psgTypeList: [
                {label: 1, text: "成人"},
                {label: 2, text: "儿童"},
                {label: 3, text: "成人+儿童"},
            ],
            psgTypeList1: [
                {label: 1, text: "成人"},
                {label: 2, text: "儿童"},
            ],
            // 出发站点
            flightFromStation: {},
            // 目的站点
            flightToStation: {},
            // 航司列表
            airlinesList: [],

            // 火车票表单
            trainForm: {
                fromStationCode: "", //出发站点
                toStationCode: "", //目的站点
                time: moment().format("YYYY-MM-DD"),
            },
            onlySearch: false,
            // 火车票出发站点
            trainFromStation: {},
            // 火车票到达站点
            trainToStation: {},

            // 旅游表单
            travelForm: {
                fromCityName: "", //出发城市
                fromCityId: "",
                toCityName: "", //目的城市
                toCityId: "",
            },

            travelTabValue: "1",
            travelTabList: [
                {label: "全部", name: "1"},
                {label: "国内/出境游", name: "2"},
            ],
            // 旅游出发城市
            travelFromCity: null,
            // 旅游目的城市
            travelToCity: null,
            // 旅游推荐城市列表
            travelRecommendCityList: [],
            // 旅游热门城市列表
            travelHotCityList: [],

            // 酒店表单
            hotelForm: {
                currentPage: 1, //
                pageSize: 10, //
                productType: 3, //产品类型
                countryType: "1", //国别
                cityName: "", //入住城市名
                cityId: "", //入住城市id
                checkInDate: moment().format("YYYY-MM-DD"), //入住时间
                checkOutDate: moment()
                    .add(1, "days")
                    .format("YYYY-MM-DD"), //退房时间
                hotelStar: "", //星级
                keyword: "", //关键字
            },

            hotelTabList: [
                {label: "国内", name: "1"},
                {label: "港澳台", name: "2"},
                {label: "国际", name: "3"},
            ],
            // 港澳台
            HONGKONGMacaoTaiWan: [
                {label: "香港", value: "30000898"},
                {label: "澳门", value: "70139"},
                {label: "台湾", value: "30001017"},
            ],
            // 国内酒店城市
            hotelInlandCity: null,
            // 国外酒店城市
            hotelForeignCity: null,

            // 离店时间禁用
            hotelOutDateOption: {
                disabledDate: this.disabledHotelEndDate,
            },

            // 酒店星级
            hotelStarList: [
                {label: "不限", value: ""},
                {label: "经济型", value: "2"},
                {label: "舒适型", value: "3"},
                {label: "高档型", value: "4"},
                {label: "豪华型", value: "5"},
            ],

            //轮播图数据
            slideDatas: {
                waitSecond: 10, //图片停留时间seconds
                list: [],
            },
            // 待付款数据
            obligationsDatas: [],

            // 公告数据
            notificationDatas: [],
            // 热门城市
            hotCity_list: [
                {
                    cityId: "110000",
                    cityName: "北京",
                },
                {
                    cityId: "120100",
                    cityName: "天津",
                },
                {
                    cityId: "110000",
                    cityName: "北京",
                },
                {
                    cityId: "120100",
                    cityName: "天津",
                },
                {
                    cityId: "110000",
                    cityName: "北京",
                },
                {
                    cityId: "120100",
                    cityName: "天津",
                },
            ],
            // 特供产品
            specialProductsDatas: {},

            // 热门推荐产品
            recommendDatas: [],
            // 分类产品列表
            classifyProductDatas: [],

            classifyTabList: [],

            userInfo: {},

            // pnr 下单
            pnr: "",
            showPnrTips: true,
            pnrSubmitLoading: false,
        };
    },
    watch: {
        // 机票国内国外改变
        flightTabValue(val) {
            const flightForm = this.flightForm;
            this.flightFromStation = null;
            this.flightToStation = null;
            switch (val) {
                case "1":
                    flightForm.ClassType = 0;
                    flightForm.adultCount = 0;

                    this.flightFromStation = {
                        airports: false,
                        cityCode: "",
                        cityName: "",
                        code: "CAN",
                        initial: "G",
                        isHot: "Y",
                        name: "广州",
                        name_custom_001: "广州(CAN)",
                        spell: "Guangzhou",
                    };

                    this.flightToStation = {
                        airports: false,
                        cityCode: "",
                        cityName: "",
                        code: "BJS",
                        initial: "B",
                        isHot: "Y",
                        name: "北京",
                        name_custom_001: "北京(BJS)",
                        spell: "Beijing",
                    };
                    break;
                case "2":
                    flightForm.ClassType = 3;
                    flightForm.adultCount = 1;
                    break;
            }
        },
        // 机票出发城市
        flightFromStation(val) {
            const flightForm = this.flightForm;
            if (val) {
                flightForm.starCity = val.code;
                flightForm.starCity1 = val.name;
            } else {
                flightForm.starCity = "";
                flightForm.starCity1 = "";
            }
        },
        // 机票目的城市
        flightToStation(val) {
            const flightForm = this.flightForm;
            if (val) {
                flightForm.endCity = val.code;
                flightForm.endCity1 = val.name;
            } else {
                flightForm.endCity = "";
                flightForm.endCity1 = "";
            }
        },
        // 机票国际出发城市
        flightFromCityObj(val) {
            const flightForm = this.flightForm;
            if (val) {
                flightForm.starCity = val.code;
                flightForm.starCity1 = val.name;
            } else {
                flightForm.starCity = "";
                flightForm.starCity1 = "";
            }
        },
        // 机票国际目的城市
        flightToCityObj(val) {
            const flightForm = this.flightForm;
            if (val) {
                flightForm.endCity = val.code;
                flightForm.endCity1 = val.name;
            } else {
                flightForm.endCity = "";
                flightForm.endCity1 = "";
            }
        },

        // 监听往返
        "flightForm.airType"(val) {
            const flightForm = this.flightForm;
            switch (val) {
                case 1:
                    flightForm.time2Show = true;
                    flightForm.time2 = null;
                    break;
                case 2:
                    flightForm.time2Show = false;
                    flightForm.time2 = moment(flightForm.time1).add(1, 'd').format('x');
                    if (flightForm.psgType === 3) flightForm.psgType = "";
                    break;
            }
        },
        // 火车票出发城市
        trainFromStation(val) {
            this.trainForm.fromStationCode = val.stationCode;
        },
        // 火车票目的城市
        trainToStation(val) {
            this.trainForm.toStationCode = val.stationCode;
        },

        // 旅游部分
        travelFromCity(val) {
            this.travelForm.fromCityName = val.areaName || "";
            this.travelForm.fromCityId = val.id || "";
        },
        travelToCity(val) {
            this.travelForm.toCityName = val.areaName || "";
            this.travelForm.toCityId = val.id || "";
        },

        // 国别
        "hotelForm.countryType"(val) {
            const hotelForm = this.hotelForm;

            if (hotelForm.cityName) {
                hotelForm.cityId = "";
                hotelForm.cityName = "";
            }
            if (val === "1") {
                this.hotelForeignCity = null;
                hotel_common_buyerBase_getCity({cityName: this.cityName})
                    .then((result) => {
                        this.hotelInlandCity = {
                            cityId: result.data.cityId.toString(),
                        };
                    })
                    .catch((err) => {
                    });
            } else if (val === "3") {
                this.hotelInlandCity = null;
            }
        },

        hotelInlandCity(val) {
            const hotelForm = this.hotelForm;
            if (val) {
                hotelForm.cityId = val.cityId;
                hotelForm.cityName = val.cityCn;
            }
        },

        hotelForeignCity(val) {
            const hotelForm = this.hotelForm;
            if (val) {
                hotelForm.cityId = val.cityId;
                hotelForm.cityName = val.cityCn;
            }
        },
    },
    methods: {
        // 获取航司
        req_airlines() {
            return new Promise((resolve, reject) => {
                get_airlines({pageSize: 100000})
                    .then((result) => {
                        let airlinesList = result.pageResult.pageData;
                        airlinesList.map((item) => {
                            item.value = item.airCompanyName + "(" + item.airlineCode + ")";
                        });
                        this.airlinesList = airlinesList;
                        resolve(result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 获取旅游推荐城市列表
        req_recommendCityList() {
            return new Promise((resolve, reject) => {
                get_recommendCityList()
                    .then((result) => {
                        this.travelRecommendCityList = result.recommendCityList;
                        resolve(result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 获取旅游热门城市列表
        req_hotCityList() {
            return new Promise((resolve, reject) => {
                get_hotCityList()
                    .then((result) => {
                        this.travelHotCityList = result;
                        resolve(result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 请求轮播图
        req_slide() {
            return new Promise((resolve, reject) => {
                web_common_advert_showList({advertPictureType: 2})
                    .then((result) => {
                        this.slideDatas = result;
                        resolve(result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 请求待付款数据
        req_obligations() {
            return new Promise((resolve, reject) => {
                web_common_TourismMall_waitPaymentList()
                    .then((result) => {
                        this.obligationsDatas = result.waitPayments;
                        resolve(result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 请求公告数据
        req_notification() {
            const __this = this;
            return new Promise((resolve, reject) => {
                web_common_TourismMall_noticeList({currentPage: 1, pageSize: 3})
                    .then((result) => {
                        const list_001 = result.pageResult.pageData;
                        const list_002 = _.chain(list_001)
                            .filter(function (m, i) {
                                return i < 3;
                            })
                            .value()
                        ;

                        __this.notificationDatas = list_002;
                        resolve(result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 请求特供产品
        req_specialProductsDatas() {
            return new Promise((resolve, reject) => {
                web_common_TourismMall_specialSupplyList({otherNum: 8})
                    .then((result) => {
                        const datas = result.tourismProductResultList[0];
                        const obj = {
                            managementImage: datas.managementImage,
                            list: [...datas.tourismProductClassifieList[0].solrProductList],
                        };

                        this.specialProductsDatas = obj;
                        resolve(result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 请求产品列表
        req_product_list(city) {
            // cityName出发城市，firstNum首推产品数量，otherNum跟团游等的数量，searchType需要请求那些
            return new Promise((resolve, reject) => {
                get_productList({
                    fromCityName: city.cityName,
                    fromCityId: city.cityId,
                    firstNum: 4,
                    otherNum: 8,
                    searchType: "",
                })
                    .then((result) => {
                        let list = result.tourismProductResultList;

                        for (var i = 0; i < list.length; i++) {
                            this.classifyTabList.push(0);
                        }
                        this.classifyProductDatas = list;
                        resolve(reject);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 请求首推产品列表
        req_recommend_product_list(city) {
            return new Promise((resolve, reject) => {
                get_recommend_productList({
                    fromCityName: city.cityName,
                    fromCityId: city.cityId,
                    firstNum: 4,
                })
                    .then((result) => {
                        let datas = result.tourismProductResultList;
                        /*datas.forEach(value=>{
                            if(value.managementTypeText === '自由行') {
                                value.managementImage = 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2024/05/lQDPJx0yE1yRG6HNBQDNAtCwt1pkP2J0_8kGQosGzCARAA_720_1280.jpg'
                            }
                        })*/
                        datas[0].tourismProductClassifieList.forEach((item) => {
                            item.solrProductList = item.solrProductList.splice(0, 4);
                        });

                        this.recommendDatas = datas[0].tourismProductClassifieList;
                        resolve(result);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 请求各个数据
        req_datas(city) {
            this.req_product_list(city), // 请求旅游产品
                this.req_recommend_product_list(city), // 请求首推产品列表
                Promise.all([
                    this.req_airlines(), // 机票获取航班
                    this.req_recommendCityList(), // 获取旅游推荐城市
                    this.req_hotCityList(), // 旅游热门城市
                    this.req_slide(), // 轮播图
                    this.req_obligations(), // 代付款列表
                    this.req_notification(), // 公告
                    this.req_specialProductsDatas(), // 特供
                ])
                    .then((result) => {
                        this.loading = false;
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
        },

        set_default() {
            this.trainFromStation = {
                firstLetter: "G",
                isHotCity: 1,
                jianpin: "gz",
                sn: 704,
                stationCode: "GZQ",
                stationLevel: 1,
                stationName: "广州",
                stationPinYin: "guangzhou",
            };
            this.trainToStation = {
                firstLetter: "B",
                isHotCity: 1,
                jianpin: "bj",
                sn: 131,
                stationCode: "BJP",
                stationLevel: 1,
                stationName: "北京",
                stationPinYin: "beijing",
            };
        },

        // 初始化
        init() {
            this.set_default();
            this.loading = true;
            // 给机票城市组件提示语
            this.$refs.flightFromStation.init({placeholder_text: "请选择出发城市"});
            this.$refs.flightFromCityObj.init({placeholder_text: "请选择出发城市"});
            this.$refs.flightToStation.init({placeholder_text: "请选择目的城市"});
            this.$refs.flightToCityObj.init({placeholder_text: "请选择目的城市"});

            // 给火车票城市组件提示语
            this.$refs.trainFromStation.init({
                placeholder_text: "请选择出发城市",
            });
            this.$refs.trainToStation.init({
                placeholder_text: "请选择目的城市",
            });
            // 给旅游城市组件提示语
            // this.$refs.travelFromCity.init({ placeholder_text: "请选择出发城市" });
            // this.$refs.travelToCity.init({ placeholder_text: "请选择目的城市" });

            // 获取帐号信息
            get_user_info().then((res) => {
                this.userInfo = res;
            });

            const localCity = get_local_city();

            if (localCity.cityName) {
                this.req_datas(localCity);
                this.cityName = localCity.cityName;
            } else {
                web_common_TourismMall_getArea()
                    .then((result) => {
                        this.req_datas({
                            cityName: result.cityName,
                            cityId: result.areaId,
                        });
                        this.cityName = result.cityName;
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
            }
        },
        // 警告提示框
        popup_warning(message) {
            this.$message({type: "warning", message, offset: 100});
        },
        // 切换搜索业务
        change_businessType(val) {
            this.businessType = val;
            const flightInit = () => {
                this.$nextTick(() => {
                    this.$refs.flightFromStation.init({
                        placeholder_text: "请选择出发城市",
                    });
                    this.$refs.flightFromCityObj.init({
                        placeholder_text: "请选择出发城市",
                    });
                    this.$refs.flightToStation.init({
                        placeholder_text: "请选择目的城市",
                    });
                    this.$refs.flightToCityObj.init({
                        placeholder_text: "请选择目的城市",
                    });
                });
            };

            const travelInit = () => {
                this.$nextTick(() => {
                    this.$refs.travelFromCity.init({
                        placeholder_text: "请选择出发城市",
                    });
                    this.$refs.travelToCity.init({
                        placeholder_text: "请选择目的城市",
                    });

                    this.travelFromCity = {
                        areaName: '广州市',
                        cityCode: '020',
                        id: 440100,
                        initial: "G",
                        levelType: 3,
                        remark1: "行政区",
                        spell: "Guangzhou",
                        spellInitial: "GZ"
                    };
                    this.travelToCity = {
                        areaName: '北京市',
                        cityCode: '010',
                        id: 110100,
                        initial: "B",
                        levelType: 3,
                        remark1: "行政区",
                        spell: "Beijing",
                        spellInitial: "BJ"
                    };
                });
            };

            const hotelInit = () => {
                if (this.hotelForm.countryType !== "1") return;
                hotel_common_buyerBase_getCity({cityName: this.cityName})
                    .then((result) => {
                        this.hotelInlandCity = {
                            cityId: result.data.cityId.toString(),
                        };
                    })
                    .catch((err) => {
                    });
            };

            switch (val) {
                case "1":
                    flightInit();
                    break;
                case "2":
                    travelInit();
                    break;
                case "3":
                    hotelInit();
                    break;
                case "4":
                    break;
            }
        },
        // 航司
        selectItem(val) {
            this.flightForm.airlines = val;
        },
        // 机票表单如果是往返时，当出发时间改变
        change_flight_time1(val) {
            const flightForm = this.flightForm;
            if (flightForm.airType === 2) {
                if (!flightForm.time2 || val > flightForm.time2) {
                    flightForm.time2 = val + 1000 * 60 * 60 * 24;
                }
            }
        },
        // 机票表单如果是往返时，当返回时间改变
        change_flight_time2() {
        },

        // 去机票搜索页
        to_flight_search() {
            const flightForm = this.flightForm;
            // 判断国内外
            const flightTabValue = this.flightTabValue;
            // 是否往返
            const airType = flightForm.airType;
            // 警告语对象
            const warningObj = {
                starCity: "请选择出发城市",
                starCity1: "请选择出发城市",
                endCity: "请选择目的城市",
                endCity1: "请选择目的城市",
                time1: "请选择出发时间",
                time2: "请选择返回时间",
            };

            // 必填参数
            let requiredArr = [
                "starCity",
                "starCity1",
                "endCity",
                "endCity1",
                "time1",
            ];
            // 是否往返
            if (airType === "2") requiredArr.push("time2");
            // 需要传的参数
            let paramsKeyArr = [
                "airType",
                "starCity",
                "starCity1",
                "endCity",
                "endCity1",
                "time1",
                "time2",
                "time2Show",
                "ClassType",
                "airlines",
                "psgType",
            ];

            // 传参对象
            let airdata = null;
            for (const key in flightForm) {
                if (requiredArr.includes(key) && !flightForm[key])
                    return this.popup_warning(warningObj[key]);
            }
            // 当时国际航班时
            if (flightTabValue === "2") {
                paramsKeyArr = [
                    ...paramsKeyArr,
                    "adultCount",
                    "childCount",
                    "babyCount",
                ];
                if (
                    !flightForm.adultCount &&
                    !flightForm.childCount &&
                    !flightForm.babyCount
                )
                    return this.popup_warning("请选择乘客人数");
            }
            let params = {};
            paramsKeyArr.forEach((item) => {
                params[item] = flightForm[item];
            });
            airdata = JSON.stringify(params);

            this.$router.push({
                name: flightTabValue === "1" ? "air-list" : "international-air-list",
                query: {airdata},
            });
        },

        // 火车票出发目的城市替换
        train_replace_city() {
            const trainFromStation = {...this.trainFromStation};
            this.trainFromStation = {...this.trainToStation};
            this.trainToStation = {...trainFromStation};
        },
        //去火车票搜索页
        to_train_search() {
            const trainForm = this.trainForm;
            const warningObj = {
                fromStationCode: "请选择出发城市",
                toStationCode: "请选择目的城市",
                time: "请选择出发时间",
            };

            for (const key in trainForm) {
                if (!trainForm[key]) return this.popup_warning(warningObj[key]);
            }

            const query = {...this.trainForm, onlySearch: this.onlySearch ? 1 : 0};
            this.$router.push({
                name: "distributor-train-front-search",
                query,
            });
        },

        // 旅游表单部分

        to_travel_search() {
            const travelForm = this.travelForm;

            if (!travelForm.fromCityName && !travelForm.toCityName) {
                return this.popup_warning("请选择出发城市或目的城市");
            }

            this.$router.push({
                name: "distributor-tour-front-search",
                query: {
                    ...travelForm,
                    // 是否带注册地城市搜索
                    is_auto_add_city: false,
                },
            });
        },
        // 点击热门推荐城市
        click_hot_city_tag(item) {
            const forwardType = item.forwardType; //跳转分类 1,按城市名搜索(出发城市) 2,按城市名搜索(目的地城市) 3,按关键字查询
            let query = {};
            switch (forwardType.toString()) {
                case "1":
                    query.fromCityId = item.areaIds;
                    query.fromCityName = item.areaName;
                    break;
                case "2":
                    query.toCityId = item.areaIds;
                    query.toCityName = item.areaName;
                    break;
                case "3":
                    query.travelKeywords = item.areaName;
                    break;
                case "4":
                    query.travelKeywords = item.areaName;
                    break;
            }
            query.is_auto_add_city = false;
            this.$router.push({
                name: "distributor-tour-front-search",
                query,
            });
        },
        // 点击城市分类推荐城市去搜索
        click_classify_recommend_city(item) {
            this.click_hot_city_tag({
                forwardType: item.forwardType,
                areaIds: item.cityIds,
                areaName: item.cityName,
            });
        },
        // 点击城市分类城市去搜索
        click_classify_city(item) {
            this.click_hot_city_tag({
                forwardType: item.forwardType,
                areaIds: item.cityIds,
                areaName: item.cityName,
            });
        },
        // 点击旅游产品管理分类侧边图去搜索
        click_managementType_image(managementType) {
            this.$router.push({
                name: "distributor-tour-front-search",
                query: {
                    managementType,
                },
            });
        },

        // 酒店
        // 港澳台选择城市
        change_hotel_city(val) {
            const obj = this.HONGKONGMacaoTaiWan.filter((item) => {
                if (item.value === val) return item;
            })[0];
            this.hotelForm.cityName = obj.label;
        },
        // 酒店离店时间禁用判断
        disabledHotelEndDate(time) {
            if (this.hotelForm.checkInDate) {
                return time.getTime() < new Date(this.hotelForm.checkInDate).getTime();
            } else {
                return time.getTime() < Date.now();
            }
        },
        // 入住时间联动离店时间
        onCheckInDateChange(value) {
            if (value) {
                let nowData = this.$moment(value).add(1, "days");
                this.$set(this.hotelForm, "checkOutDate", nowData.format("yyyy-MM-DD"));
            } else {
                this.$set(this.hotelForm, "checkOutDate", "");
            }
        },
        //去酒店搜索页
        to_hotel_search() {
            const form = this.hotelForm;
            const requiredArr = ["cityName", "checkInDate", "checkOutDate"];
            const warningObj = {
                cityName: "请选择目的城市",
                checkInDate: "请选择入住时间",
                checkOutDate: "请选择离店时间",
            };

            for (let key = 0; key < requiredArr.length; key++) {
                if (!form[requiredArr[key]])
                    return this.popup_warning(warningObj[requiredArr[key]]);
            }

            this.$router.push({
                name: "distributor-hotel-front-search",
                query: {
                    data: JSON.stringify({...form}),
                },
            });
        },

        // 当点击轮播图
        click_swiper(item) {
            // advertForwardType跳转方式 0：无链接    1：关键字(不带城市)     2：按关键字(带城市)     3：按关键字(带注册城市)    4：按链接     5：按照产品编号搜索   现在是这样的
            const {
                advertForwardType, //跳转方式
                advertLink, //跳转链接
                productNo, //产品编号
                advertKeyword, //关键字
                areaId, //城市id
                areaName, //城市名称
            } = item;

            const jump = (query) => {
                this.$router.push({
                    name: "distributor-tour-front-search",
                    query,
                });
            };

            switch (advertForwardType) {
                case 0:
                    break;
                case 1:
                    jump({travelKeywords: advertKeyword, is_auto_add_city: false});
                    break;
                case 2:
                    jump({
                        travelKeywords: advertKeyword,
                        is_auto_add_city: false,
                        fromCityId: areaId,
                        fromCityName: areaName,
                    });
                    break;
                case 3:
                    jump({
                        travelKeywords: advertKeyword,
                        is_auto_add_city: true,
                    });
                    break;
                case 4:
                    window.open(advertLink, "_blank");
                    break;
                case 5:
                    this.to_detail(productNo);
                    break;
            }
        },

        // 去待支付
        to_obligations(businessType) {
            let name = "";
            let query = "";

            let jump = () => {
                this.$router.push({
                    name,
                    query,
                });
            };

            // 1、机票，2、旅游，3、酒店，4、火车，
            switch (businessType.toString()) {
                case "1":
                    name = "air-ticket-admin-purchase-order-list";
                    query = {status: "2"};
                    jump();
                    break;
                case "2":
                    name = "distributor-tour-back-tour-with-group-order-list";
                    query = {orderState: "2"};
                    jump();
                    break;
                case "3":
                    name = "hotel-admin-my-order-list";
                    query = {orderStatus: "0"};
                    jump();
                    break;
                case "4":
                    name = "distributor-train-back-train-order-list";
                    query = {orderStatus: "2"};
                    jump();
                    break;
            }
        },
        // 点击公告more去公告列表栏页
        to_notification_list() {
            this.$router.push({
                name: "distributor-notice-list",
            });
        },
        // 去公告详情
        to_notification_detail(noticeId, noticeType = 1) {
            this.$router.push({
                name: "distributor-notice-detail",
                query: {
                    noticeId,
                    noticeType,
                },
            });
        },
        // 点击特供产品more去搜索页
        to_search_special() {
            this.$router.push({
                name: "distributor-tour-front-search",
                query: {
                    isSpecialSupply: 1,
                },
            });
        },

        // 点击推荐部分去更多
        recommend_to_more(classifyType) {
            this.$router.push({
                name: "distributor-tour-front-recommend-product",
                query: {
                    classifyType,
                },
            });
        },

        // 管理分类去搜索
        more_to_search(managementType) {
            let query = {managementType};
            if ([3].includes(managementType)) query.is_auto_add_city = false;
            this.$router.push({
                name: "distributor-tour-front-search",
                query,
            });
        },

        // 改变管理分类的tab
        change_tab(index, i) {
            this.$set(this.classifyTabList, index, i);
        },

        to_detail(productNo) {
            const {href} = this.$router.resolve({
                name: "distributor-tour-front-details",
                query: {
                    productNo,
                },
            });
            window.open(href, "_blank");
        },

        // 隐藏 pnr 提示
        onTipsClick() {
            this.showPnrTips = false;
            this.$refs.pnrTextarea.focus();
        },
        // 失去焦点显示 pnr 提示
        onPnrTextareaBlur() {
            if (this.pnr === "") {
                this.showPnrTips = true;
            }
        },
        // 清空 pnr
        onClearPnr() {
            this.pnr = "";
            this.showPnrTips = true;
        },
        // pnr 导入下单
        onPnrSubmit() {
            if (this.pnr === "") {
                this.$message({
                    message: "请输入 PNR",
                    type: "error",
                    offset: 60,
                });
                return;
            }
            this.pnrSubmitLoading = true;
            buyer_dom_white_pnrImport({
                rtContent: this.pnr,
            })
                .then((data) => {
                    if (data.dfdList[0].policys.length === 0) {
                        this.$alert("未查到相应航班政策", "提示", {
                            confirmButtonText: "确定",
                        });
                        return;
                    }
                    this.$router.push({
                        name: "flight-pnr",
                        query: {id: data.uuid},
                    });
                })
                .finally(() => {
                    this.pnrSubmitLoading = false;
                });
        },
    },
    created() {
    },
    mounted() {
        this.init();
    },
};
