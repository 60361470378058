const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`)

/**
 * 首页获取待付款列表  http://219.128.52.2:9999/doc.html#/yinzhilv_travel/商城首页/getWaitPaymentListUsingPOST
 */
export default function getDetail(data) {
    var pParameter = {};
    pParameter.method = "POST";
    pParameter.urlSuffix = "/web/common/TourismMall/waitPaymentList";
    pParameter.data = data;
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
};
